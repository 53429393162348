var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "card-browser-states", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c(
            "div",
            [
              _c("b-card-title", [_vm._v("Browser States")]),
              _c("b-card-text", { staticClass: "font-small-2" }, [
                _vm._v(" Counter August 2020 ")
              ])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "chart-dropdown",
              attrs: { variant: "link", "no-caret": "", "toggle-class": "p-0" },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("feather-icon", {
                        staticClass: "text-body cursor-pointer",
                        attrs: { icon: "MoreVerticalIcon", size: "18" }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("b-dropdown-item", { attrs: { href: "#" } }, [
                _vm._v(" Last 28 Days ")
              ]),
              _c("b-dropdown-item", { attrs: { href: "#" } }, [
                _vm._v(" Last Month ")
              ]),
              _c("b-dropdown-item", { attrs: { href: "#" } }, [
                _vm._v(" Last Year ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card-body",
        _vm._l(_vm.browserData, function(browser, index) {
          return _c(
            "div",
            { key: browser.browserImg, staticClass: "browser-states" },
            [
              _c(
                "b-media",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-media-aside",
                    { staticClass: "mr-1" },
                    [
                      _c("b-img", {
                        attrs: { src: browser.browserImg, alt: "browser img" }
                      })
                    ],
                    1
                  ),
                  _c("b-media-body", [
                    _c("h6", { staticClass: "align-self-center my-auto" }, [
                      _vm._v(" " + _vm._s(browser.name) + " ")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold text-body-heading mr-1" },
                    [_vm._v(_vm._s(browser.usage))]
                  ),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radialBar",
                      height: "30",
                      width: "30",
                      options: _vm.chartData[index].options,
                      series: _vm.chartData[index].series
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }