var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "dashboard-ecommerce" } },
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { xl: "4", md: "6" } },
            [
              _c("ecommerce-medal", {
                attrs: { data: _vm.data.congratulations }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "8", md: "6" } },
            [
              _c("ecommerce-statistics", {
                attrs: { data: _vm.data.statisticsItems }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "4" } },
            [
              _c(
                "b-row",
                { staticClass: "match-height" },
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "6", md: "3", cols: "6" } },
                    [
                      _c("ecommerce-order-chart", {
                        attrs: { data: _vm.data.statisticsOrder }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { lg: "6", md: "3", cols: "6" } },
                    [
                      _c("ecommerce-profit-chart", {
                        attrs: { data: _vm.data.statisticsProfit }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { lg: "12", md: "6" } },
                    [
                      _c("ecommerce-earnings-chart", {
                        attrs: { data: _vm.data.earningsChart }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "8" } },
            [
              _c("ecommerce-revenue-report", {
                attrs: { data: _vm.data.revenue }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "8" } },
            [
              _c("ecommerce-company-table", {
                attrs: { "table-data": _vm.data.companyTable }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [_c("ecommerce-meetup", { attrs: { data: _vm.data.meetup } })],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [_c("ecommerce-browser-states")],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c("ecommerce-goal-overview", {
                attrs: { data: _vm.data.goalOverview }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c("ecommerce-transactions", {
                attrs: { data: _vm.data.transactionData }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }